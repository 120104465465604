// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED"
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED"
export const SET_USER = "SET_USER"
export const LOADING_USER = "LOADING_USER"
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ"
export const SET_PROFILE = "SET_PROFILE"
export const EDIT_CREDENTIALS = "EDIT_CREDENTIALS"
// UI reducer types
export const SET_ERRORS = "SET_ERRORS"
export const LOADING_UI = "LOADING_UI"
export const CLEAR_ERRORS = "CLEAR_ERRORS"
export const SET_OLD_PATH = "SET_OLD_PATH"
// Data reducer types
export const SET_SCREAMS = "SET_SCREAMS"
export const SET_SCREAM = "SET_SCREAM"
export const LOADING_DATA = "LOADING_DATA"
export const LIKE_SCREAM = "LIKE_SCREAM"
export const UNLIKE_SCREAM = "UNLIKE_SCREAM"
export const DELETE_SCREAM = "DELETE_SCREAM"
export const POST_SCREAM = "POST_SCREAM"
export const SUBMIT_COMMENT = "SUBMIT_COMMENT"